<template>
    <section class="content">
        <div class="container-fluid">
            <div  class="row">
                <div v-for="row in optMapel" class="col-md-4 col-sm-8 col-12">
                <router-link :to="{
                    path: '/materi-ajar-content/'+row.id
                }">
                    <div class="info-box shadow-sm">
                    <span class="info-box-icon bg-info shadow"><img src="https://img.icons8.com/external-vitaliy-gorbachev-lineal-color-vitaly-gorbachev/50/000000/external-books-back-to-school-vitaliy-gorbachev-lineal-color-vitaly-gorbachev.png"/></span>
                    <div class="info-box-content">
                        <span class="info-box-text">{{ row.mapel }}</span>
                        <span class="info-box-number">{{ !row.qty_materi ? 0 : row.qty_materi}} Materi</span>
                    </div>
                    </div>
                </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
export default {
    name: 'Materi',
    data: () => ({
        optMapel: [],
    }),
    created: function () {
        // this.loadMateri();
    },
    methods: {
        loadMateri(){
            authFetch("/tentor/materi").then((res) => {
                res.json().then((json) => {
                if (json.success) {
                    this.optMapel = json.data;
                }
                });
            });
        }
    },
    mounted(){
        this.loadMateri();
    },
}
</script>
<style type="text/css" scoped>
</style>